<template>
  <div>
    <nav-bar :isNavWx="true" :bgc="'background:#fff'" :isFixed="true" :title="$t('选择退货商品')"></nav-bar>
    <div class="box-main">
      <div class="good-item" v-for="(item,index) in list" :key="index">
        <div class="header">
          <div class="radio" @click="handleChecked(index)">
            <img v-if="item.isChecked" src="@/assets/icons/green_select.png">
            <img v-else src="@/assets/icons/none_select.png">
          </div>
          <div class="name">{{ item.storeName }}</div>
        </div>
        <div class="good-info" v-for="(goods,idx) in item.goods" :key="idx">
          <div class="good-left">
            <img :src="`${getProductImageUrl(goods.picturePath,400)}`" alt="" @error="handleImageError" />
          </div>
          <div class="good-right">
            <div class="title">{{ goods.productName }}</div>
            <div class="specs">{{ goods.standard }}</div>
            <div class="unitPrice">
              <div class="price">{{ formatNumber(goods.price) }}</div>
              <div class="unit">数量{{ goods.amount }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="footer">
      <div class="top">
        <div class="left">
          <div class="radio">
            <img src="@/assets/icons/did_select.png" v-if="!Allchecked" @click="handleOnAllchecked(true)">
            <img v-else src="@/assets/icons/green_select.png" @click="handleOnAllchecked(false)">
          </div>
          <div class="allSelect">{{ $t('全选') }}</div>
        </div>
        <divc class="right">
          已選{{standardLength}}件商品
        </divc>
      </div>
      <div class="bottom">
        <div class="left">退款金額<span>{{ formatNumber(Totalprice) }}</span></div>
        <div class="right" @click="handlenext">下一步</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getChildOrders } from "@/api/user.js"
import mixins from "@/utils/mixins.js"
export default {
  name: "selectGoods",
  mixins: [mixins],
  data () {
    return {
      list: [],
      orderNo:null,
      standardLength: 0,
      Totalprice: 0
    }
  },
  computed: {
    Allchecked () {
      if (this.list.length) {
        const blom = this.list.every(item => item.isChecked)
        return blom
      } else {
        return false
      }
    },
  },
  mounted () {
    this.orderNo = this.$route.params.orderNo
    if (this.orderNo) {
      this.getData(this.orderNo)
    }
  },
  methods: {
    handlenext () {
      this.$router.push(`/user/returnGoodsDetil/${this.orderNo}`)

    },
    handleChecked (index) {
      this.list[index].isChecked = !this.list[index].isChecked
      this.getLength()
    },
    handleOnAllchecked (data) {
      if (this.list.length) {
        this.list.forEach(item => {
          item.isChecked = data
        })
        this.getLength()
      }
    },
    // 获取已选长度
    getLength () {
      // 所有商品的数量
      this.standardLength = this.list.filter(item => item.isChecked).reduce(
        (prve, next) => Number(prve) + Number(next.goodsNum),
        0
      )
      // 选中的商品合计
      this.Totalprice = this.list.filter(item => item.isChecked).reduce((prve, next) => {
        return Number(prve) + Number(next.totalPrice)
      },
        0
      )

    },
    async getData (data) {
      const res = await getChildOrders({ id: data })
      this.list = res.data.map(item => {
        return {
          ...item,
          isChecked: false,
          goodsNum: item.goods.reduce(
            (prve, next) => Number(prve) + Number(next.amount),
            0
          )
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.radio {
  width: 24px;
  height: 24px;
  font-size: 0;

}

img {
  width: 100%;
  height: 100%;
}

.box-main {
  background-color: #ffffff;
  padding: 50px 16px 120px;
  
  .good-item {
    padding-top: 16px;
    .header {
      display: flex;
      align-items: center;

      .name {
        font-weight: 500;
        font-size: 16px;
        color: #1A1A1A;
        line-height: 24px;
        margin-left: 12px;
      }
      &:first-child(){
        padding-top:0
      }
    }

    .good-info {
      padding: 15px 0 15px 36px;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #F8F8F8;

      .good-left {
        width: 82px;
        height: 82px;
        font-size: 0;
      }

      .good-right {
        margin-left: 10px;
        flex: 1;
        .title {
          font-weight: 500;
          font-size: 14px;
          color: #1A1A1A;
          line-height: 22px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          margin-bottom: 8px;
        }

        .specs {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 18px;
          margin-bottom: 12px;
        }

        .unitPrice {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .price {
            font-weight: 500;
            font-size: 14px;
            color: #1A1A1A;
            line-height: 22px;
          }

          .unit {
            font-weight: 500;
            font-size: 14px;
            color: #1A1A1A;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.footer {
  position: fixed;
  background: #FFFFFF;
  width: 100%;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  height: 100px;

  .top {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .allSelect {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
      }
    }

    .right {
      font-weight: 400;
      font-size: 14px;
      color: #1A1A1A;
      line-height: 22px;
    }
  }

  .bottom {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #1A1A1A;
      line-height: 22px;

      span {
        font-weight: 800;
        font-size: 20px;
        color: #F82207;
        line-height: 30px;
      }
    }

    .right {
      width: 120px;
      height: 44px;
      background: #90D12E;
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
    }
  }
}
</style>